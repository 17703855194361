@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed&display=swap');

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  @apply font-roboto;
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

body {
  color: white;
  background-color: #121212;
  font-family: 'Roboto, Saira Condensed';
  @apply cursor-default;
}
